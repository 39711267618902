import React, { useState } from 'react';
import { BrandButton } from 'ui/BrandButton/BrandButton';
import {
  SectionBackdrop,
  SectionClock,
  SectionContainer,
  SectionEditButtonWrapper,
  SectionHeader,
  SectionWrapper,
} from './styles';
import { Clock } from 'common/Clock/Clock';
import { Button, Fade } from '@mui/material';
import { SelectImageDialog } from 'common/SelectImageDialog/SelectImageDialog';
import { useEffect } from 'react';
import { ArrowBackIosRounded } from '@mui/icons-material';

interface Props {
  editMode: boolean;
  time: boolean;
  backButton: string;
  image: string;
  handleImageChange: (path: string) => void;
}

export const SectionBg: React.FC<Props> = ({
  editMode = false,
  time = true,
  backButton = '',
  image = '',
  handleImageChange,
}: Props) => {
  const [currentStyle, setCurrentStyle] = useState<Record<string, string>>({
    backgroundSize: image ? 'cover' : 'unset',
    backgroundRepeat: 'no-repeat',
  });
  const [hover, setHover] = useState<boolean>(false);
  const [selectImageDialogOpen, setSelectImageDialogOpen] =
    useState<boolean>(false);

  const handleBack = () => {
    console.log('handleBack');
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  useEffect(() => {
    setCurrentStyle({
      ...currentStyle,
      backgroundImage: 'url(' + process.env.REACT_APP_SERVER_URL + image + ')',
      backgroundSize: image ? 'cover' : 'unset',
    });
  }, [image]);

  return (
    <SectionWrapper
      sx={{
        cursor: editMode ? 'pointer' : 'initial',
        border: editMode ? `1px solid rgba(0,0,0,.05)` : 'none',
      }}
    >
      <SectionContainer
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={currentStyle}
      >
        <>
          <SectionHeader>
            {!!backButton && (
              <BrandButton
                onClick={handleBack}
                size="small"
                color="primary"
                style={{ background: 'white' }}
                startIcon={<ArrowBackIosRounded fontSize="small" />}
              >
                Назад
              </BrandButton>
            )}
            {time && (
              <SectionClock>
                <Clock />
              </SectionClock>
            )}
          </SectionHeader>
          {editMode && (
            <>
              <Fade in={hover} timeout={150}>
                <SectionEditButtonWrapper htmlFor="mainBgInput">
                  <Button
                    component="span"
                    style={{ textTransform: 'none' }}
                    color="primary"
                    variant="outlined"
                    onClick={() => setSelectImageDialogOpen(true)}
                  >
                    {image ? 'Изменить изображение' : 'Загрузить изображение'}
                  </Button>
                  <SelectImageDialog
                    endPoint={'/terminal/admin/banners/image/main'}
                    open={selectImageDialogOpen}
                    setOpen={setSelectImageDialogOpen}
                    onFileChange={handleImageChange as any}
                  />
                </SectionEditButtonWrapper>
              </Fade>
              <Fade in={hover} timeout={150}>
                <SectionBackdrop />
              </Fade>
            </>
          )}
        </>
      </SectionContainer>
    </SectionWrapper>
  );
};
