import { Fade } from '@mui/material';
import { EditWisiwygBox } from 'HOC/EditWisiwygBox/EditWisiwygBox';
import {
  BlockComponent,
  BlocksData,
  BLOCK_TYPE,
  TBlock,
} from 'interface/wisiwyg';
import React from 'react';
import { ImageBlock } from './Blocks/ImageBlock';
import { ListBlock } from './Blocks/ListBlock';
import { ParagraphBlock } from './Blocks/ParagraphBlock';
import { TitleBlock } from './Blocks/TitleBlock';

type Props = BlockComponent & {
  data: TBlock;
  setData: React.Dispatch<React.SetStateAction<BlocksData>>;
  allData: BlocksData;
};

export const Block: React.FC<Props> = ({
  data,
  allData,
  handleEditBody,
  handleDeleteBlock: deleteBlock,
  index,
  setData,
  editMode = false,
}: Props) => {
  const props = {
    handleEditBody,
    handleDeleteBlock: deleteBlock,
    index,
    editMode,
    ...data,
  };

  const handleDeleteBlock = () => {
    if (deleteBlock) deleteBlock(index);
  };

  const handleMoveUp = () => {
    if (index !== allData.blocks.length - 1) {
      setData((prevData) => {
        const blocksCopy = [...prevData.blocks];
        blocksCopy[index] = [
          blocksCopy[index + 1],
          (blocksCopy[index + 1] = blocksCopy[index]),
        ][0];

        console.log('blocksCopty', blocksCopy);

        return {
          ...prevData,
          blocks: blocksCopy,
        };
      });
    }
  };

  const handleMoveDown = () => {
    if (index !== 0) {
      setData((prevData) => {
        const blocksCopy = [...prevData.blocks];
        blocksCopy[index] = [
          blocksCopy[index - 1],
          (blocksCopy[index - 1] = blocksCopy[index]),
        ][0];

        console.log('blocksCopty', blocksCopy);

        return {
          ...prevData,
          blocks: blocksCopy,
        };
      });
    }
  };

  function detectComponent(b: TBlock) {
    /* typescript заставляет меня писать такое */
    if (b.type === BLOCK_TYPE.PARAGRAPH) {
      return <ParagraphBlock {...props} {...b} />;
    } else if (b.type === BLOCK_TYPE.LIST) {
      return <ListBlock {...props} {...b} />;
    } else if (b.type === BLOCK_TYPE.IMAGE) {
      return <ImageBlock {...props} {...b} />;
    } else {
      return <TitleBlock {...props} {...b} />;
    }
  }

  return (
    <EditWisiwygBox
      editMode={editMode}
      handleMoveUp={handleMoveUp}
      handleMoveDown={handleMoveDown}
      handleDeleteBlock={handleDeleteBlock}
    >
      <Fade in timeout={300}>
        {detectComponent(data)}
      </Fade>
    </EditWisiwygBox>
  );
};
