import React from 'react';
import { Box, Grid2 } from '@mui/material';
import { BLOCK_TYPE } from 'interface/wisiwyg';
import { AddBlockBarWrapper, AddBlockButtonStyled } from './styles';
import { Image, Subject, Title, List } from '@mui/icons-material';

const buttons: BlockButton[] = [
  { title: 'Заголовок', Icon: Title, type: BLOCK_TYPE.TITLE },
  { title: 'Текст', Icon: Subject, type: BLOCK_TYPE.PARAGRAPH },
  { title: 'Изображение', Icon: Image, type: BLOCK_TYPE.IMAGE },
  { title: 'Список', Icon: List, type: BLOCK_TYPE.LIST },
];

interface BlockButton {
  title: string;
  Icon: React.FC;
  type: BLOCK_TYPE;
}

interface AddBlockButtonProps extends BlockButton {
  handleAddBlock: (type: BLOCK_TYPE) => void;
}

export const AddBlockBar: React.FC<
  Pick<AddBlockButtonProps, 'handleAddBlock'>
> = ({ handleAddBlock }: Pick<AddBlockButtonProps, 'handleAddBlock'>) => {
  return (
    <Box
      mt={{
        xs: 2,
        md: 4,
      }}
      mb={{
        xs: 4,
        md: 8,
      }}
    >
      <AddBlockBarWrapper>
        <Grid2 container spacing={2}>
          {buttons.map((button) => (
            <Grid2 key={button.title} sx={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <AddBlockButton {...button} handleAddBlock={handleAddBlock} />
            </Grid2>
          ))}
        </Grid2>
      </AddBlockBarWrapper>
    </Box>
  );
};

const AddBlockButton = ({
  title,
  Icon,
  type,
  handleAddBlock,
}: AddBlockButtonProps) => {
  return (
    <Box
      p={{
        xs: 1,
        sm: 2,
      }}
    >
      <AddBlockButtonStyled
        startIcon={<Icon />}
        onClick={() => handleAddBlock(type)}
      >
        <div style={{ fontSize: 16 }}>{title}</div>
      </AddBlockButtonStyled>
    </Box>
  );
};
