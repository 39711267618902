import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import DetailBlock from './DetailBlock';
import { Box, Button, TextField } from '@mui/material';
import AddDetailTypeBar from 'components/AddDetailTypeBar/AddDetailTypeBar';
import { DetailType } from 'enums/global';
import { makeEmptyBlock } from './Blocks/utils/makeEmptyBlock';
import { doRequest } from 'hooks/doRequest';
import { postEditDetailsScreen } from 'api/sections';
import moment from 'moment';
import { toast } from 'react-toastify';

const empty = {
  action: null,
  data: [],
  title: 'Пустой заголовок',
  id: -1,
};

const DetailEdit = ({ allData }: any) => {
  const { blockId } = useParams<{ blockId: string }>();
  const [block, setBlock] = useState(
    allData?.find((d: any) => String(d.id) === blockId) || empty
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setBlock(allData?.find((d: any) => String(d.id) === blockId) || empty);
    console.log(block);
  }, [allData]);

  const handleSelect = (type: DetailType) => {
    setBlock((prev: Record<any, any>) => ({
      ...prev,
      data: [...(prev?.data || []), makeEmptyBlock(block?.id || -1, type)],
    }));
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBlock((prev: Record<any, any>) => ({
      ...prev,
      title: e.target.value,
    }));
  };

  const moveTop = (number: number) => () => {
    const myIndex = block.data[number];
    const prevItem = block.data[number - 1];
    const newDetails = block.data;
    newDetails[number] = prevItem;
    newDetails[number - 1] = myIndex;
    setBlock((prev: Record<any, any>) => ({
      ...prev,
      data: newDetails,
    }));
  };
  const moveBottom = (number: number) => () => {
    const myIndex = block.data[number];
    const next = block.data[number + 1];
    const newDetails = block.data;
    newDetails[number] = next;
    newDetails[number + 1] = myIndex;
    setBlock((prev: Record<any, any>) => ({
      ...prev,
      data: newDetails,
    }));
  };

  const handleDelete = (index: number) => () => {
    setBlock((prev: Record<any, any>) => ({
      ...prev,
      data: prev.data.filter((_: any, ind: number) => ind !== index),
    }));
  };

  const handleSaveDetailsScreen = async () => {
    setLoading(true);
    const body = { ...block };
    body['uploadTime'] = moment().format('yyyy-MM-DD[T]HH:MM:ss');
    body.data = body.data.map((det: any) => {
      if (det.type === DetailType.icons) {
        const icons = det.iconsItems.map((i: any) => {
          if (typeof i.id === 'string') {
            return {
              ...i,
              id: -1,
            };
          }
          return i;
        });
        det.iconsItems = icons;
        return det;
      }
      return det;
    });
    const res: any = await doRequest(postEditDetailsScreen, body);

    if (res.data?.data) {
      toast.success('Экран сохранён');
    } else {
      toast.error('Ошибка сохранения');
    }

    setLoading(false);
  };

  const handleSaveDetail = (ind: number) => (body: any) => {
    setBlock((prev: any) => ({
      ...prev,
      data: prev.data.map((item: any, i: number) => {
        if (i === ind) {
          return body;
        } else {
          return item;
        }
      }),
    }));
  };

  return (
    <Box
      style={{
        width: 400,
      }}
    >
      <TextField
        value={block.title}
        placeholder="Заголовок экрана"
        onChange={handleTitleChange}
        style={{ marginBottom: 32, fontWeight: 600 }}
        fullWidth
        multiline
      />
      {block?.data?.map((block: any, ind: number) => (
        <DetailBlock
          moveTop={moveBottom(ind)}
          moveBottom={moveTop(ind)}
          delete={handleDelete(ind)}
          setData={handleSaveDetail(ind)}
          key={ind}
          {...block}
        />
      ))}
      <Button
        onClick={handleSaveDetailsScreen}
        color="primary"
        variant="contained"
        fullWidth
        disabled={loading}
      >
        {loading ? 'Сохранение...' : 'Сохранить'}
      </Button>
      <AddDetailTypeBar onSelect={handleSelect} />
    </Box>
  );
};

export default DetailEdit;
