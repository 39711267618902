import React from 'react';
import { ILink } from './interface/global';
/* import HomeIcon from '@mui/icons-material/Home'; */
import { lightBlue, green } from '@mui/material/colors';
import { ResourceInstance } from '@devexpress/dx-react-scheduler';
import {
  AddAlert,
  AssignmentInd,
  Dashboard,
  EventNote,
  FolderShared,
  Group,
  LineStyle,
  ListAlt,
  Person,
  ViewDay,
} from '@mui/icons-material';

export type Divider = string;

type AdminLinks = Array<ILink | Divider>;

export const ADMIN_LINKS: AdminLinks = [
  /* { link: '/admin/home', title: 'Главная', icon: <HomeIcon /> }, */
  { link: '/admin/reserving', title: 'Бронирование', icon: <EventNote /> },
  { link: '/admin/profile', title: 'Профиль', icon: <Person /> },
  { link: '/admin/guests', title: 'Гости', icon: <Group /> },
  { link: '/admin/wisiwyg', title: 'Баннеры', icon: <LineStyle /> },
  {
    link: '/admin/notify',
    title: 'Общее уведомление',
    icon: <AddAlert />,
  },
];

export const DRAWER_LINKS = [
  /*{
    link: '/admin/guests',
    title: 'Гости',
    Icon: SupervisedUserCircleIcon,
    menu: [
      { title: 'Список', link: '/admin/guests/list', icon: <ListAltIcon /> },
      {
        title: 'Создание',
        link: '/admin/guests/create',
        icon: <PersonAddSharpIcon />,
      },
      {
        title: 'Бронирование',
        link: '/admin/guests/reserving',
        icon: <EventNoteIcon />,
      },
    ],
  },
  {
    link: '/admin/terminal',
    title: 'Терминал',
    Icon: VideoLabelIcon,
    menu: [
      {
        title: 'Баннеры',
        link: '/admin/terminal/banners',
        icon: <ListAltIcon />,
      },
      {
        title: 'Добавить баннер',
        link: '/admin/terminal/createbanner',
        icon: <PostAddIcon />,
      },
      {
        title: 'Общее уведомление',
        link: '/admin/terminal/notify',
        icon: <AddAlertIcon />,
      },
      {
        title: 'Разделы',
        link: '/admin/terminal/sections',
        icon: <ViewDayIcon />,
      },
      {
        title: 'Детальные экраны',
        link: '/admin/terminal/endblocks',
        icon: <DashboardIcon />,
      },
    ],
  },
  {
    link: '/admin/personal',
    title: 'Персонал',
    Icon: AssignmentIndIcon,
    menu: [
      {
        title: 'Список',
        link: '/admin/personal/list',
        icon: <ListAltIcon />,
      },
      {
        title: 'Общее уведомление',
        link: '/admin/personal/notify',
        icon: <AddAlertIcon />,
      },
    ],
  },*/
  {
    link: '/admin/info',
    title: 'Главная',
    Icon: AssignmentInd,
    menu: [
      {
        title: 'Баннеры',
        link: '/admin/info/banners',
        icon: <ListAlt />,
      },
      {
        title: 'Разделы',
        link: '/admin/info/sections',
        icon: <ViewDay />,
      },
      {
        title: 'Детальные экраны',
        link: '/admin/info/endblocks',
        icon: <Dashboard />,
      },
      {
        title: 'Общие',
        link: '/admin/info/shared',
        icon: <FolderShared />,
      },
    ],
  },
];

export const CALENDAR_GROUPS: ResourceInstance[] = [
  { text: 'Врач', id: 1, color: lightBlue },
  { text: 'Пациент', id: 2, color: green },
];

export const mainColor = '#278CF5';
export const mainGradient =
  'linear-gradient(239.51deg, #6FB5FF 22.34%, #488DFF 77.81%)';
export const purpleGradient =
  'linear-gradient(232.84deg, #7880FF 22.99%, #5B64EC 80.51%)';
export const lightGradient =
  'linear-gradient(143.03deg, #B5CFF7 21.47%, #A0C6FF 95.48%)';
export const greenGradient =
  'linear-gradient(244.06deg, #2ADE9B 26.41%, #19D18D 75.21%)';
