import BlocksPage from 'pages/Blocks/BlocksPage';
import EndScreens from 'pages/EndScreens/EndScreens';
import InfoBanners from 'pages/InfoBanners/InfoBanners';
import SharedBlocks from 'pages/SharedBanners/SharedBlocks';
import React from 'react';
import { Route } from 'react-router';

const InfoPage = () => {
  return (
    <>
      <Route path={'/admin/info/sections'}>
        <BlocksPage pathname="/admin/info/sections" />
      </Route>
      <Route path={'/admin/info/endblocks'}>
        <EndScreens pathname="/admin/info/endblocks" />
      </Route>
      <Route path={'/admin/info/banners'}>
        <InfoBanners />
      </Route>
      <Route path={'/admin/info/shared'}>
        <SharedBlocks pathname="/admin/info/shared" />
      </Route>
    </>
  );
};

export default InfoPage;
