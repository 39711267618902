import { Typography } from '@mui/material';
import { useDebounce } from 'hooks/useDebounce';
import { BlockComponent, BLOCK_TYPE, IBlock } from 'interface/wisiwyg';
import React from 'react';
import { TitleBlockBody } from '../styles';

export const TitleBlock: React.FC<
  BlockComponent & IBlock<BLOCK_TYPE.TITLE>
> = ({
  value: outerValue,
  editMode,
  index,
  handleEditBody,
}: BlockComponent & IBlock<BLOCK_TYPE.TITLE>) => {
  const [value, handleChange] = useDebounce(
    outerValue,
    (newValue: string) => handleEditBody(newValue, index),
    400,
    true
  );

  /* const handleDelete = () => {
      if (handleDeleteBlock) handleDeleteBlock(index);
      else {
        console.error('Вы не указали функцию удаления');
      }
    }; */

  return editMode ? (
    <>
      <TitleBlockBody
        placeholder="Введите заголовок..."
        value={value}
        fullWidth
        multiline
        onChange={handleChange}
      />
    </>
  ) : (
    <>
      <Typography component="h6">{outerValue}</Typography>
    </>
  );
};
