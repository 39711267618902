import { getCurrentSection, getCurrentSectionBlocks } from 'api/sections';
import useGetData from 'hooks/useGetData';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { CircularProgress, Typography } from '@mui/material';
import { PaperWrapper } from 'HOC/PaperWrapper/PaperWrapper';
import BlockLIsts from 'components/BlockLists/BlockLIsts';
import BlockEditComponent from 'components/BlockEditComponent/BlockEditComponent';
import { BrandButton } from 'ui/BrandButton/BrandButton';
import { makeEmptyInfoBlock } from 'utils/makeEmptyInfoBlock';
import { InfoBlockEnum } from 'enums/global';

const BlockList = () => {
  const { sectionId } = useParams<{ sectionId: string }>();
  const [history, setHistory] = useState<any[]>([]);
  const { loading, data, getData } = useGetData<any>({
    request: getCurrentSectionBlocks,
    requestBody: Number(sectionId),
  });
  const name = useGetData<any>({
    request: getCurrentSection,
    requestBody: Number(sectionId),
  });

  const navigate = useHistory();

  const handleBack = () => {
    const currPathname = navigate.location.pathname.split('/');
    const prevPathname = currPathname
      .slice(0, currPathname.length - 1)
      .join('/');
    navigate.push(prevPathname);
  };

  const handleCreateClick = () => {
    setHistory((prev) => [
      ...prev,
      makeEmptyInfoBlock({
        parent: null,
        type: InfoBlockEnum.room,
        section: Number(sectionId),
      }),
    ]);
  };

  const handleClick = (id: number) => () => {
    setHistory((prev) => [...prev, data.find((i: any) => i.id === id)]);
  };

  if (loading || !data || !name) {
    return <CircularProgress />;
  }

  if (history.length) {
    return (
      <PaperWrapper editMode style={{ padding: 8 }}>
        <>
          <Typography variant={'h6'}>Редактирование</Typography>

          <BlockEditComponent
            refetch={getData}
            history={history}
            data={history[history.length - 1]}
            setHistory={setHistory}
          />
        </>
      </PaperWrapper>
    );
  }

  return (
    <PaperWrapper editMode style={{ padding: 8 }}>
      <>
        <Typography variant={'h6'}>{name.data?.data.name}</Typography>

        <BrandButton
          style={{ margin: '16px 0' }}
          onClick={handleBack}
          color="primary"
          variant="contained"
        >
          Назад
        </BrandButton>

        <BlockLIsts
          handleClick={handleClick}
          handleCreateClick={handleCreateClick}
          data={data}
          startBlocks={name.data?.directChildBlocks}
        />
      </>
    </PaperWrapper>
  );
};

export default BlockList;
