import { Add } from '@mui/icons-material';
import React from 'react';

export interface AddButtonProps {
  title?: string;
  onClick?: () => void;
  size?: number;
  noLabel?: boolean;
}

export const AddButton: React.FC<AddButtonProps> = ({
  title,
  onClick,
  size,
  noLabel,
}: AddButtonProps) => {
  if (noLabel) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 8,
          cursor: 'pointer',
          border: '2px dashed rgba(0,0,0,.4) ',
          width: size || 160,
          height: size || 160,
          marginLeft: 16,
        }}
      >
        <Add style={{ color: 'rgba(0,0,0,.4)', height: 32 }} />
      </div>
    );
  }

  return (
    <label
      onClick={onClick || undefined}
      htmlFor={title ? `${title}_input` : undefined}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 8,
          cursor: 'pointer',
          border: '2px dashed rgba(0,0,0,.4) ',
          width: size || 160,
          height: size || 160,
          marginLeft: 16,
        }}
      >
        <Add style={{ color: 'rgba(0,0,0,.4)', height: 32 }} />
      </div>
    </label>
  );
};
