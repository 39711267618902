import { Box, Fade, InputBase } from '@mui/material';
import { SelectImageDialog } from 'common/SelectImageDialog/SelectImageDialog';
import { useDebounce } from 'hooks/useDebounce';
import { BlocksData } from 'interface/wisiwyg';
import React, { useState } from 'react';
import { DotsIcon } from 'svgComponents/DotsIcon';
import { BrandButton } from 'ui/BrandButton/BrandButton';
import defaultPreview from 'images/defaultPreview.png';
import {
  PlateContent,
  PlateDescription,
  PlateDots,
  PlateInfoWrapper,
  PlateTitle,
  PlateWrapper,
  PreviewImgWrapper,
} from './styles';

interface Props {
  data: BlocksData;
  setData: React.Dispatch<React.SetStateAction<BlocksData>>;
}

export const Preview: React.FC<Props> = ({ data, setData }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const [localValue, handleChange] = useDebounce(
    data.shortDescription,
    (newValue: string) => setData({ ...data, shortDescription: newValue }),
    300,
    true
  );

  const [titleValue, handleTitleChange] = useDebounce(
    data.title,
    (newValue: string) => setData({ ...data, title: newValue }),
    300,
    true
  );

  const handleImageChange = (path: string) => {
    setData((prevData) => ({
      ...prevData,
      preview: path,
    }));
  };

  return (
    <Fade in timeout={300}>
      <Box
        pb={3}
        pt={3}
        display="flex"
        justifyContent="center"
        style={{ background: 'rgba(189, 213, 249, 1)' }}
      >
        <PlateWrapper>
          <PlateContent>
            <PreviewImgWrapper>
              <img
                src={
                  data.preview
                    ? `${process.env.REACT_APP_SERVER_URL}${data.preview}`
                    : defaultPreview
                }
                onClick={() => setOpen(true)}
              />
              <SelectImageDialog
                endPoint={'/terminal/admin/banners/image/preview'}
                open={open}
                setOpen={setOpen}
                onFileChange={handleImageChange as any}
              />
            </PreviewImgWrapper>
            <PlateInfoWrapper>
              <PlateTitle>
                <InputBase
                  value={titleValue}
                  onChange={handleTitleChange}
                  placeholder="Заголовок"
                />
              </PlateTitle>
              <PlateDescription>
                <InputBase
                  value={localValue}
                  onChange={handleChange}
                  placeholder="Описание"
                />
              </PlateDescription>
              <Box width={116} margin="0 auto 0 0">
                <BrandButton variant="outlined" color="primary" size="small">
                  Подробнее
                </BrandButton>
              </Box>
            </PlateInfoWrapper>
          </PlateContent>
          <PlateDots>
            <DotsIcon />
          </PlateDots>
        </PlateWrapper>
      </Box>
    </Fade>
  );
};
