import { Box, Button, Dialog, Grid2, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { FC } from 'react';
import { useHistory } from 'react-router';

interface Props {
  open: boolean;
  onClose: () => void;
  id: number | undefined;
  name: string | undefined;
  inBlock: boolean;
  onDelete: (id: number) => Promise<AxiosResponse<any, any>>;
}

const DeleteDialog: FC<Props> = ({
  open,
  onClose,
  id,
  name,
  inBlock,
  onDelete,
}) => {
  const history = useHistory();
  const handleClose = () => {
    onClose();
  };
  const handleDelete = () => {
    if (id) {
      onDelete(id);
    }
    handleClose();
    if (inBlock) {
      const route = history.location.pathname.split('/');
      history.push(`${route.slice(0, route.length - 1).join('/')}`);
    }
    window.location.reload();
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <Grid2 container justifyContent="center">
        <Typography
          variant="body1"
          style={{ margin: '24px', textAlign: 'center' }}
        >
          Вы уверены, что хотите удалить &quot;{name}&quot;
        </Typography>
        <Box width="100%" display="flex" justifyContent="space-between" m={2}>
          <Button onClick={handleDelete} style={{ marginLeft: '24px' }}>
            <Typography variant="body1" color="error">
              Удалить
            </Typography>
          </Button>
          <Button onClick={handleClose} style={{ marginRight: '24px' }}>
            <Typography variant="body1">Отмена</Typography>
          </Button>
        </Box>
      </Grid2>
    </Dialog>
  );
};

export default DeleteDialog;
