import { Container, styled } from '@mui/material';

export const SectionWrapper = styled('div')(() => ({
  width: '100%',
  minHeight: 180,
  transition: '.3s',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

export const SectionContainer = styled(Container)(() => ({
  width: '100%',
  minHeight: 180,
  transition: '.3s',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

export const SectionHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: '32px 0px',
}));

export const SectionClock = styled('div')(() => ({
  color: 'black',
  background: 'white',
  borderRadius: 12,
  padding: '8px 16px',
}));

export const SectionEditButtonWrapper = styled('label')(() => ({
  display: 'block',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 999,
}));

export const SectionBackdrop = styled('div')(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  background: 'rgba(0,0,0,.1)',
}));
