import React from 'react';
import { useState } from 'react';
import { LittleCloseIcon } from 'svgComponents/LittleCloseIcon';
import { MediaType } from 'types/global';
import { DeleteWrapper, IconItemStyle, ImgWrapper } from './styles';

interface Props {
  icon: MediaType;
  onClick: (icon: MediaType) => void;
  onDelete: (icon: MediaType) => void;
}

export const IconItem: React.FC<Props> = (props: Props) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <IconItemStyle
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isHover && (
        <DeleteWrapper onClick={() => props.onDelete(props.icon)}>
          <LittleCloseIcon />
        </DeleteWrapper>
      )}
      <ImgWrapper
        onClick={() => props.onClick(props.icon)}
        src={`${props.icon.downloadUrl}`}
      />
    </IconItemStyle>
  );
};
