import { styled } from '@mui/material';

export const BlockEditStyled = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: 32,
  width: 500,

  '& > *': {
    marginBottom: 32,
  },
}));
