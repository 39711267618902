import React from 'react';
import { Pagination as MuiPagination } from '@mui/lab';

interface Props {
  totalCount?: number;
  onPageChange?: (newPage: number) => void;
  onRowChange?: (newRow: number) => void;
  page: number;
}

export const Pagination: React.FC<Props> = ({
  totalCount = 1,
  onPageChange,
  page,
}: Props) => {
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (onPageChange) onPageChange(value);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 16,
      }}
    >
      <MuiPagination
        count={totalCount}
        variant="outlined"
        color="primary"
        shape="rounded"
        onChange={handlePageChange}
        page={page}
      />
    </div>
  );
};
