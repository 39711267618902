import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';

let interval: NodeJS.Timeout;

export const Clock: React.FC = () => {
  const [time, setTime] = useState<string>(
    new Date().toLocaleTimeString('ru', {
      hour: '2-digit',
      minute: '2-digit',
    })
  );

  const tick = () => {
    setTime(
      new Date().toLocaleTimeString('ru', {
        hour: '2-digit',
        minute: '2-digit',
      })
    );
  };

  useEffect(() => {
    interval = setInterval(tick, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box fontFamily="Rubik" fontWeight={500} fontSize={30} color={'#101010'}>
      {time}
    </Box>
  );
};
