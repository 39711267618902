import { Menu } from '@mui/material';
import { IBackgroundGradient } from 'interface/notify';
import React from 'react';
import { useMenuHandle } from 'hooks/useMenuHandle';
import { ColorPicker } from 'common/ColorPicker';

interface Props {
  possibleBackgrounds: IBackgroundGradient[];
  onTextColorChange: (newValue: string) => void;
  onBackggroundColorChange: (fieldName: string, newValue: string) => void;
  textColor: string;
  backgroundColor: { startColor: string; endColor: string };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ColorPickers: React.FC<Props> = ({
  onTextColorChange,
  onBackggroundColorChange,
  textColor,
  backgroundColor,
}: Props) => {
  const { handleOpen, windowProps } = useMenuHandle();

  const { handleOpen: handleStartOpen, windowProps: startWindowProps } =
    useMenuHandle();

  const { handleOpen: handleEndOpen, windowProps: endWindowProps } =
    useMenuHandle();

  const handleTextColorChange = (newColor: string) =>
    onTextColorChange(newColor);

  return (
    <>
      <div>
        <div style={{ marginTop: 16 }}>
          <div>Цвет текста:</div>
          <div
            onClick={handleOpen}
            style={{
              borderRadius: '100%',
              width: 48,
              height: 48,
              cursor: 'pointer',
              backgroundColor: textColor,
              border: '1px solid rgba(0,0,0,.2)',
            }}
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <div>Цвет фона:</div>
          <div>
            <div
              onClick={handleStartOpen}
              style={{
                borderRadius: '100%',
                width: 48,
                height: 48,
                cursor: 'pointer',
                border: '1px solid rgba(0,0,0,.2)',
                backgroundColor: backgroundColor.startColor,
                display: 'inline-block',
              }}
            />
            <div
              onClick={handleEndOpen}
              style={{
                borderRadius: '100%',
                width: 48,
                height: 48,
                cursor: 'pointer',
                border: '1px solid rgba(0,0,0,.2)',
                backgroundColor: backgroundColor.endColor,
                marginLeft: 8,
                display: 'inline-block',
              }}
            />
          </div>
        </div>
      </div>
      <Menu {...windowProps}>
        <ColorPicker
          initialColor={textColor}
          handleColorChange={handleTextColorChange}
        />
      </Menu>
      <Menu {...startWindowProps}>
        <ColorPicker
          initialColor={backgroundColor.startColor}
          handleColorChange={(color: string) =>
            onBackggroundColorChange('startColor', color)
          }
        />
      </Menu>
      <Menu {...endWindowProps}>
        <ColorPicker
          initialColor={backgroundColor.endColor}
          handleColorChange={(color: string) =>
            onBackggroundColorChange('endColor', color)
          }
        />
      </Menu>
    </>
  );
};
