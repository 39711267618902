import { Typography, Fade, Box, Tabs, Tab } from '@mui/material';
import { getCurrentBanner, postEditBanner } from 'api/banner';
import { PaperWrapper } from 'HOC/PaperWrapper/PaperWrapper';
import { doRequest } from 'hooks/doRequest';
import { ResponseError } from 'interface/global';
import { BlocksData } from 'interface/wisiwyg';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { BrandButton } from 'ui/BrandButton/BrandButton';
import { TabPanel } from 'ui/TabPanel/TabPanel';
import { FullInfo } from './FullInfo';
import { Preview } from './Preview';

export const Wisiwyg: React.FC = () => {
  const params = useParams<{ bannerId?: string }>();
  const history = useHistory();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);

  const [data, setData] = useState<BlocksData>({
    id: -1,
    title: '',
    shortDescription: '',
    preview: '',
    mainImage: '',
    isActive: false,
    blocks: [],
  });

  const getBannerInfo = async () => {
    const res = await doRequest<BlocksData>(getCurrentBanner, {
      id: params?.bannerId || 0,
    });
    if (res.success) {
      setData(res.data);
    } else {
      toast.error('Ошибка получения баннера');
      history.push('/admin/terminal/banners');
    }
  };

  useEffect(() => {
    if (params.bannerId && +params.bannerId > -1) {
      getBannerInfo();
    }
  }, []);

  const handleMainBgChange = (path: string) => {
    setData({
      ...data,
      mainImage: path,
    });
  };

  const handleSave = async () => {
    try {
      setIsFetching(true);
      const res = await doRequest<BlocksData | ResponseError>(
        postEditBanner,
        data
      );
      if (res.success) {
        setIsFetching(false);
        toast.success('Баннер создан');
      } else {
        setIsFetching(false);
        if (res.errors?.userMessage) {
          toast.error(res.errors.userMessage);
        } else {
          toast.error('Ошибка');
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Fade in timeout={300}>
      <div>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection="row"
        >
          <Typography variant="h2">Баннеры</Typography>
          <BrandButton
            disabled={isFetching}
            onClick={handleSave}
            color="primary"
            variant="contained"
          >
            {isFetching ? 'Подождите...' : 'Сохранить'}
          </BrandButton>
        </Box>
        <Box mb={4}>
          <Tabs value={value} onChange={handleChange} aria-label="tabs">
            <Tab style={{ textTransform: 'none' }} label="Информация" />
            <Tab style={{ textTransform: 'none' }} label="Баннер" />
          </Tabs>
        </Box>
        <PaperWrapper editMode>
          <>
            <TabPanel value={value} index={0}>
              <FullInfo
                handleMainBgChange={handleMainBgChange}
                data={data}
                setData={setData}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Preview data={data} setData={setData} />
            </TabPanel>
          </>
        </PaperWrapper>
      </div>
    </Fade>
  );
};
