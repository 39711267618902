import { Button, InputBase, Paper, styled, TextField } from '@mui/material';
import { hexToRgb } from 'utils/colors';

export const AddBlockBarWrapper = styled('div')(({ theme }) => ({
  borderTop: `3px solid ${theme.palette.primary.main}`,
  padding: 8,
  paddingTop: 24,
}));

export const AddBlockButtonStyled = styled(Button)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  transition: '.2s',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover': {
    background: hexToRgb(theme.palette.primary.main, '.1', true),
    transition: '.2s',
  },

  '& *': {
    color: theme.palette.primary.main,
  },
}));

export const UploadImageBlockLabel = styled('label')(() => ({
  border: '1px solid rgba(0,0,0,.1)',
  cursor: 'pointer',
  display: 'block',
  borderRadius: 12,
  background: 'white',
  position: 'relative',
  transition: '.3s',

  '&:hover': {
    filter: 'brightness(.9)',
    transition: '.3s',
  },
}));

export const UploadImageStyled = styled('img')(() => ({
  width: '100%',
  maxHeight: 406,
  objectFit: 'cover',
  borderRadius: 12,
}));

export const AddLiButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  textTransform: 'none',
}));

export const ParagraphBlockBody = styled(InputBase)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 22,
  color: theme.palette.text.primary,
}));

export const TitleBlockBody = styled(InputBase)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.text.primary,
  fontSize: 24,
}));

export const FullInfoTitle = styled(TextField)(({ theme }) => ({
  margin: '24px 0',
  '& textarea': {
    fontWeight: 500,
    color: theme.palette.text.primary,
    fontSize: 43,
    paddingTop: 10,
  },
}));

export const PlateWrapper = styled(Paper)(() => ({
  padding: 24,
  borderRadius: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: 396,
  height: 208,
}));

export const PlateContent = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-betewen',
  flexDirection: 'row',
}));

export const PreviewImgWrapper = styled('div')(() => ({
  widht: 128,
  height: 128,
  marginRight: 24,

  '& > img': {
    transition: '.3s',
    width: 128,
    height: 128,
    objectFit: 'cover',

    '&:hover': {
      cursor: 'pointer',
      transition: '.3s',
      filter: 'blur(3px)',
    },
  },
}));

export const PlateInfoWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const PlateTitle = styled('div')(() => ({
  fontWeight: 700,
  fontSize: 22,

  '& input': {
    fontWeight: 700,
    fontSize: 22,
  },
}));

export const PlateDescription = styled('div')(() => ({
  fontWeight: 500,
  fontSize: 16,

  '& input': {
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 16,
  },
}));

export const PlateDots = styled('div')(() => ({
  width: '100%',
  textAlign: 'center',
}));
