import React, { FC } from 'react';
import { Box, Button } from '@mui/material';
import { DetailType } from 'enums/global';

const types = [
  { title: 'Заголовок комнаты', type: DetailType.header },
  { title: 'Заголовок', type: DetailType.title },
  { title: 'Подзаголовок', type: DetailType.subtitle },
  { title: 'Текст', type: DetailType.paragraph },
  { title: 'Изображение', type: DetailType.image },
  { title: 'Список', type: DetailType.list },
  { title: 'Гелерея', type: DetailType.gallery },
  { title: 'Ссылка', type: DetailType.link },
  { title: 'Иконки', type: DetailType.icons },
];

type Props = {
  onSelect: (type: DetailType) => void;
  disable?: DetailType[];
};

const AddDetailTypeBar: FC<Props> = ({ onSelect }) => {
  const handleSelect = (type: DetailType) => () => onSelect(type);

  return (
    <Box
      style={{
        marginTop: 32,
        paddingTop: 16,
        borderTop: '2px solid rgba(0,0,0,.2)',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
      }}
    >
      {types.map((type) => (
        <Button
          variant="outlined"
          key={type.type}
          style={{ margin: 8 }}
          onClick={handleSelect(type.type)}
        >
          {type.title}
        </Button>
      ))}
    </Box>
  );
};

export default AddDetailTypeBar;

/* Title
SubTitle
Paragraph
Image
List
Gallery
Link
Icons
RoomHeader */
